import React from 'react'
import { CardContainer } from "./styles";
import Image from 'gatsby-image';
import { Link } from 'gatsby'
import { normalize } from "../../hooks/normalize";

const Card = ({
  title,
  subtitle,
  image,
  date
}) => {
  const parseDate = date ? date.split('T')[0].split('-') : '00/00/0000'
  return (
    <CardContainer className="col-lg-6 col-xl-4 col-md-6 col-sm-6">
      <Link to={`/galeria/${normalize(title)}`}>
          <div className="gradient">
          </div>
          {image &&
            <Image
              fluid={image.fluid} alt={title}
            />
          }
          <div className="container-article">
            {date &&
              <div className="date-container">
                <h5>{parseDate[2]}/{parseDate[1]}/{parseDate[0]}</h5>
              </div>
            }
            <div className="info-container">
              {title &&
                <div className="title-container">
                  <h5>{title.slice(0, 28)}{title.length > 28 && '...'}</h5>
                </div>
              }
              {subtitle &&
                <div className="subtitle-container">
                  <p>{subtitle.slice(0, 70)}{subtitle.length > 70 && '...'}</p>
                </div>
              }
            </div>
          </div>
      </Link>
    </CardContainer>
  )
}

export default Card
