import styled from '@emotion/styled';

export const RepositoryContainer = styled.div`
  background-color: #f0f0f0;
  display: flex;
  @media (max-width: 991px){
    display: block;
    background-color: #231f20;
  }
`
export const LeftMenuContainer = styled.nav`
  width: 400px;
  background-color: #231f20;
  min-height: 100vh;
  padding-top: 88px;
  & h1{
    font-family: 'SolanoGothicMVBStd-Bd';
    font-size: 107px;
    color: #fff;
    margin-left: 1.85rem;
  }
  & span{
    color: #231f20;
  }
  & .description-container h2,
  & .description-container p{
    font-family: 'ZizouSlab-Light';
    color: #fff;
    font-size: 26px;
    line-height: 1.2;
  }
  & .description-container{
    margin-left: 60px;
    margin-right: 60px;
  }
  @media (max-width: 991px){
    min-height: unset;
    width: 720px;
    padding-top: 125px;
    margin-left: auto;
    margin-right: auto;
    & span{
      color: #fff;
    }
    & h1{
      font-size: 78px;
      text-align: center;
      margin-left: unset;
    }
    & .description-container{
      margin-left: unset;
      margin-right: unset;
      padding-left: 15px;
      padding-right: 15px;
    }    
    & .description-container h2,
    & .description-container p{
      font-size: 23px;
      margin-bottom: 0px;
    }
  }
  @media (max-width: 768px){
    width: 540px;
  }
  @media (max-width: 575px){
    width: 100%;
    & .description-container h2,
    & .description-container p{
      font-size: 21px;
      text-align: center;
    }
  }
  @media (max-width: 380px){
    & h1{
      font-size: 20.6vw;
    }
    & .description-container h2,
    & .description-container p{
      font-size: 5.6vw;
    }
  }
`
export const ArticlesContainer = styled.main`
  display:flex;
  flex-wrap: wrap;
  width: calc(100% - 400px);
  margin: 224px auto 50px auto;
  @media (min-width: 1200px){
    max-width: 1140px;
  }
  @media (max-width: 991px){
    margin: 50px auto 0 auto;
    width: 720px;
  }
  @media (max-width: 768px){
    width: 540px;
  }
  @media (max-width: 575px){
    max-width: 380px;
    width: 90%;
  }
`
export const CardContainer = styled.article`
  margin-bottom: 5%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  & a{
    display: block;
    position: relative;
  }
  & .gradient{
    opacity:0;
    pointer-events: none;
    user-select: none;
    background-color: #00000000;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 30;
    transition: all .5s ease;
  }
  &:hover .gradient{
    opacity:1;
    background-color: #0000002f;
  }
  & .container-article{
    background: #e30713;
    height: 7.4rem;
  }
  & .date-container {
    background: #000000;
    text-align: left;
    padding-left: 6%;
    padding-right: 6%;
    height: 28%;
    display: flex;
    align-items: center;
  }
  & h5{
    color: #fff;
    margin-bottom: 0px;
    font-family: 'ZizouSlab-Medium';
  }
  & p{
    color: #fff;
    font-family: 'ZizouSlab-Light';
    font-size: 18px;
    line-height: 1.2;
  }
  & .date-container h5{
    font-size: 14px;
  }
  & .info-container h5{
    font-size: 18px;
    line-height: 1.2;
  }
  & .info-container{
    //height: 20%;
    padding-left: 6%;
    padding-right: 6%;
    padding-top: 2%;
    padding-bottom: 2%;
  }
  @media only screen and (max-width: 1556px){
    & .info-container h5{
      font-size: 1.15vw;
    }
    & p{
      font-size: 1.15vw;
    }
    & .date-container h5{
      font-size: 14px;
    }
    & .container-article{
      height: 7.5vw;
    }
  }
  @media only screen and (max-width: 1526px){
    & .info-container h5,
    & p{
      font-size: 1.141vw;
    }
  }
  @media only screen and (max-width: 1496px){
    & .info-container h5,
    & p{
      font-size: 1.136vw;
    }
  }
  @media only screen and (max-width: 1466px){
    & .info-container h5,
    & p{
      font-size: 1.128vw;
    }
  }
  @media only screen and (max-width: 1446px){
    & .info-container h5,
    & p{
      font-size: 1.114vw;
    }
  
  }
  @media only screen and (max-width: 1426px){
    & .info-container h5,
    & p{
      font-size: 1.108vw;
    }
    & .date-container h5{
      font-size: 13px;
    }
  }
  @media only screen and (max-width: 1406px){
    & .info-container h5,
    & p{
      font-size: 1.102vw;
    }
  }
  @media only screen and (max-width: 1386px){
    & .info-container h5,
    & p{
      font-size: 1.096vw;
    }
  }
  @media only screen and (max-width: 1366px){
    & .info-container h5,
    & p{
      font-size: 1.090vw;
    }
  }
  @media only screen and (max-width: 1346px){
    & .info-container h5,
    & p{
      font-size: 1.075vw;
    }
    & .date-container h5{
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 1326px){
    & .info-container h5,
    & p{
      font-size: 1.068vw;
    }
  }
  @media only screen and (max-width: 1306px){
    & .info-container h5,
    & p{
      font-size: 1.048vw;
    }
  }
  @media only screen and (max-width: 1286px){
    & .info-container h5,
    & p{
      font-size: 1.038vw;
    }
  }
  @media only screen and (max-width: 1266px){
    & .info-container h5,
    & p{
      font-size: 1.032vw;
    }
  }
  @media only screen and (max-width: 1246px){
    & .info-container h5,
    & p{
      font-size: 1.020vw;
    }
    & .date-container h5{
      font-size: 11px;
    }
  }
  @media only screen and (max-width: 1226px){
    & .info-container h5,
    & p{
      font-size: 1.010vw;
    }
  }
  @media only screen and (max-width: 1226px){
    & .info-container h5,
    & p{
      font-size: 1.0vw;
    }
  }
  @media only screen and (max-width: 1199px){
    & .info-container h5,
    & p{
      font-size: 1.55vw;
    }
    & .container-article{
      height: 9.3vw;
    }
    & .date-container h5{
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 1179px){
    & .info-container h5,
    & p{
      font-size: 1.52vw;
    }
  }
  @media only screen and (max-width: 1159px){
    & .info-container h5,
    & p{
      font-size: 1.50vw;
    }
  }
  @media only screen and (max-width: 1139px){
    & .info-container h5,
    & p{
      font-size: 1.49vw;
    }
  }
  @media only screen and (max-width: 1109px){
    & .info-container h5,
    & p{
      font-size: 1.47vw;
    }
    & .date-container h5{
      font-size: 15px;
    }
  }
  @media only screen and (max-width: 1089px){
    & .info-container h5,
    & p{
      font-size: 1.455vw;
    }
  }
  @media only screen and (max-width: 1069px){
    & .info-container h5,
    & p{
      font-size: 1.425vw;
    }
  }
  @media only screen and (max-width: 1049px){
    & .info-container h5,
    & p{
      font-size: 1.405vw;
    }
  }
  @media only screen and (max-width: 1029px){
    & .info-container h5,
    & p{
      font-size: 1.385vw;
    }
    & .date-container h5{
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 1009px){
    & .info-container h5,
    & p{
      font-size: 1.355vw;
    }
  }
  @media only screen and (max-width: 998px){
    & .info-container h5,
    & p{
      font-size: 1.335vw;
    }
  }
  @media only screen and (max-width: 991px){
    & .info-container h5,
    & p{
      font-size: 17px;
    }
    & .container-article{
      height: 6.6rem;
    }
    & .date-container h5{
      font-size: 15px;
    }
  }
  @media only screen and (max-width: 768px){
    & .info-container h5,
    & p{
      font-size: 0.775rem;
    }
    & .container-article{
      height: 4.8rem;
    }
    & .date-container h5{
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 575px){
    & .info-container h5,
    & p{
      font-size: 1.124rem;
    }
    & .container-article{
      height: 7rem;
    }
    & .date-container h5{
      font-size: 15px;
    }
  }
  @media only screen and (max-width: 435px){
    & .info-container h5,
    & p{
      font-size: 4.12vw;
    }
    & .container-article{
      height: 26vw;
    }
    & .date-container h5{
      font-size: 3.5vw;
    }
  }
`