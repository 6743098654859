import React from 'react'
import {
	RepositoryContainer,
	LeftMenuContainer,
	ArticlesContainer
} from "./styles";
import Card from "./Card";
import { graphql, useStaticQuery } from "gatsby"

const Repository = () => {

	const { allContentfulPortalCarruselDeImagenes: { nodes } } = useStaticQuery(
		graphql`
		{
				allContentfulPortalCarruselDeImagenes(filter: {node_locale: {eq: "en-US"}}, sort: {fields: IndexDate, order: DESC}) {
						nodes {
						id
						IndexTiltle
						IndexDate
						IndexSubtitle
						IndexImage{
							description
							title
							fluid (maxWidth: 600){
								base64
								aspectRatio
								sizes
								src
								srcSet
								srcSetWebp
							}
						}
					}
				}
		}
	`); console.log({ nodes })

	return (
		<RepositoryContainer>
			<LeftMenuContainer>
				<h1>EXHIBICION<span>ES</span></h1>
				<div className="description-container">
					<h2>
						Visita las diferentes exhibiciones de contenido artístico cultural que te ofrecemos en UPC Cultural
					</h2>
				</div>
			</LeftMenuContainer>
			<ArticlesContainer>
				{nodes.map(({ id, IndexTiltle, IndexSubtitle, IndexImage, IndexDate }) => (
					<Card
						key={id}
						title={IndexTiltle}
						subtitle={IndexSubtitle}
						image={IndexImage}
						date={IndexDate}
					/>
				))}
			</ArticlesContainer>
		</RepositoryContainer>
	)
}

export default Repository
