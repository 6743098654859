import React from 'react'
import Layout from "../components/share/layout"
import Footer from "../components/share/footer"
import Repository from "../components/repository";

const Repositorio = () => {
  return (
    <Layout
      title="Bienvenidos al repositorio de UPC Cultural | UPC Cultural"
      metaTitle="Bienvenidos al repositorio de UPC Cultural | UPC Cultural"
      description="Bienvenidos al repositorio de UPC Cultural"
      subtitile="Bienvenidos al repositorio de UPC Cultural"
      metaDescripcin="Bienvenidos al repositorio de UPC Cultural"
      imgurl="//cultural.upc.edu.pe/static/UpcCultural_Share-a1f8e23f2b58627c09d19c7bfc611366.jpg"
      keywords="repositorio, upc"
    >
      <Repository />
      <Footer />
    </Layout>
  )
}

export default Repositorio
